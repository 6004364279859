.navbar {
  background: #afafaf;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  top: 0;
  position: sticky;
  z-index: 999;
}

.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90px;
  width: 100%;
}

.navbar-logo {
  color: #ffffff;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.fa-home {
  margin-left: 0.5rem;
  font-size: 2.75rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-gap: 10px;
  width: 60vw;
  justify-content: end;
  list-style: none;
  text-align: center;
  margin-right: 2rem;
}

.nav-item {
  height: 90px;
  display: flex;
  align-items: center;
}

.nav-links {
  color: #eee;
  display: flex;
  align-items: center;
  padding: 1rem;
  text-decoration: none;
  height: 50%;
}

.nav-links:hover {
  color: #fff;
  font-weight: 300;
  border-right: 2px solid #eee;
  border-left: 2px solid #eee;
  transition: all 0.1s ease-out;
}

.fa-bars {
  color: #eee;
}

.nav-icon {
  display: none;
}

@media screen and (max-width: 960px) {

  .nav-menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 40%;
    height: 90vh;
    position: absolute;
    top: 90px;
    left: -100%;
    opacity: 1;
    transition: all 0.6s ease;
  }

  .nav-menu.active {
    background: #717171;
    left: 0;
    opacity: 1;
    z-index: 1;
    transition: all 0.5s ease;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    color: #717171;
    background-color: #eee;
    transition: all 0.4s ease-out;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
  }

  .nav-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
  }
}

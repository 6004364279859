.project-description-container {
    display: flex;
    flex-direction: column;
    max-width: 1120px;
    width: 90%;
    margin: 0 auto;
}

.project-description-container h2 {
    padding-bottom: 1%;
    padding-top: 1%;
}

.project-description-container p {
    padding-bottom: 2%;
}

.project-description-container a {
    text-decoration: none;
    color: #668dcc;
}

.video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
  }
  
  .video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
.project-container {
  display: flex;
  justify-content: center;
  max-width: 1120px;
  width: 90%;
  margin: 0 auto;
}

.project-wrapper {
  position: relative;
  margin: 50px 0 45px;
}

.project-cards {
  margin-bottom: 24px;
}

.project-card {
  display: flex;
  flex: 1;
  margin: 0 1rem;
  border-radius: 10px;
}

.project-card-link {
  display: flex;
  flex-flow: column;
  width: 100%;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  border-radius: 10px;
  overflow: hidden;
  text-decoration: none;
}

.project-card-title {
  position: relative;
  width: 100%;
  padding-top: 67%;
  overflow: hidden;
}

.project-card-title:after {
  content: attr(data-category);
  position: absolute;
  bottom: 0;
  margin-left: 10px;
  padding: 6px 8px;
  max-width: calc((100%) - 60px);
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  background-color: #afafaf;
  box-sizing: border-box;
}

.project-card-image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
  transition: all 0.2s linear;
}

.project-card-image:hover {
  transform: scale(1.05);
}

.project-card-info {
  padding: 20px 30px 30px;
}

.project-section-heading {
  text-align: center;
  padding: 50px 20px;
}

.project-card-text {
  color: black;
}

.project-skill-list {
  display: flex;
  list-style: none;
  justify-content: space-evenly;
}

.project-skill {
  font-size: 30px;
  color: black;
}

.list-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
}

@media screen and (min-width: 960px) {
  .project-card {
    width: 250px;
  }
}

@media screen and (max-width: 960px) {
  .project-container {
    display: flex;
    flex-direction: column;
  }
}
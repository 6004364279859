.bio {
  display: flex;
}
.bio-container {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 40vw;
    padding-top: 10vh;
}

.bio-profile-pic {
  filter: grayscale(.4);
  height: 35vh;
  width: auto;
  border-radius: 20%;
  padding: 1.5rem;
}

.bio-heading {
    font-size: 35px;
    font-weight: 300;
}

@media screen and (max-width: 960px) {
  .bio-container {
    display: flex;
    flex-direction: column;
  }
}

